import React from "react";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

export default function PagePays({ data, location }) {
  const post = data.markdownRemark;
  return (
    <Layout location={location}>
      <Helmet>
        <meta
          name="description"
          content={`Appeler la Sécu, EDF ou tout autre service français depuis ${post.frontmatter.countryNameArticle} ☎️ ${post.frontmatter.phoneNumberA} `}
        />
        <meta name="keywords" content={post.frontmatter.keywords} />
      </Helmet>
      <div className="card my-3">
        <div className="row p-3">
          <div className="p-3">
            {post.frontmatter.countryName !== "Suisse" && (
              <div>
                <span>
                  <h3>
                    Depuis {post.frontmatter.countryNameArticle} composez le:
                    <br />
                    <a href={`tel:${post.frontmatter.phoneNumberA}`}>
                      {post.frontmatter.phoneNumberA}
                    </a>
                  </h3>
                  {post.frontmatter.instructionsPrixPays}
                  <br />
                  Comprendre{" "}
                  <Link to="/comprendre-le-prix-de-votre-appel-0.html">
                    le prix de votre appel
                  </Link>
                  . La{" "}
                  <Link to="/comprendre-le-prix-de-votre-appel-0.html">
                    durée de l’appel peut être limitée
                  </Link>
                  .
                </span>
                {post.frontmatter.phoneNumberB && (
                  <span>
                    <h3>
                      <br />
                      Vous pouvez également composer le: <br />
                      <a href={`tel:${post.frontmatter.phoneNumberB}`}>
                        {post.frontmatter.phoneNumberB}
                      </a>
                    </h3>
                    {post.frontmatter.instructionsPrixPays}
                    <br />
                    Comprendre{" "}
                    <Link to="/comprendre-le-prix-de-votre-appel-0.html">
                      le prix de votre appel
                    </Link>
                    . La{" "}
                    <Link to="/comprendre-le-prix-de-votre-appel-0.html">
                      durée de l’appel peut être limitée
                    </Link>
                    .
                  </span>
                )}
              </div>
            )}
            {post.frontmatter.countryName === "Suisse" && (
              <h3>
                Depuis {post.frontmatter.countryNameArticle} composez le:
                <br />
                <span>
                  <a href={`tel:${post.frontmatter.phoneNumberA}`}>
                    {post.frontmatter.phoneNumberA}
                  </a>
                  {" - "}
                  {post.frontmatter.phonePriceA}
                  <br />
                  {post.frontmatter.instructionsPrixPays}
                </span>
                {post.frontmatter.phoneNumberB && (
                  <span>
                    <br />
                    ou le
                    <br />
                    <a href={`tel:${post.frontmatter.phoneNumberB}`}>
                      {post.frontmatter.phoneNumberB}
                    </a>
                    {" - "}
                    {post.frontmatter.phonePriceB}
                    <br />
                    {post.frontmatter.instructionsPrixPays}
                  </span>
                )}
              </h3>
            )}
          </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="row p-3">
          <div className="p-3">
            <div>
              <h3>Attendez et suivez les instructions en français</h3>
              <div>{post.frontmatter.instructionsAppelPays}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="row p-3">
          <div className="p-3">
            <div>
              <h3>Parlez comme si vous étiez en France</h3>
              <div>
                <p>
                  Recevez des{" "}
                  <Link to="/conseils-pour-reussir-votre-appel.html">
                    conseils
                  </Link>{" "}
                  pour réussir votre appel.
                </p>
                <p>
                  Vous voulez en savoir plus sur le déroulement de votre appel?
                </p>
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-3" />
            <div className="col-9">
              {post.frontmatter.instructionsServiceClientPays && (
                <span>
                  <h3>Service client appelerdeletranger.com</h3>
                  <em>{post.frontmatter.instructionsServiceClientPays}</em>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PagePaysQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
        countryName
        countryNameArticle
        description
        keywords
        phoneNumberA
        phonePriceA
        # phoneNumberB
        # phonePriceB
        instructionsPrixPays
        instructionsAppelPays
        # instructionsServiceClientPays
      }
    }
  }
`;
